<template lang="pug">
.inspiration-list__item(
  :class="{ 'is-loading': !isLoaded, 'is-preview': isPreview, 'is-hidden': !id, 'show-mask': hasBackground }"
  :style="containerStyle"
  @mouseenter="isHovered = true"
  @mouseleave="isHovered = false; isVideoLoaded = false"
  v-touch:tap="chooseTemplate"
)
  span.inspiration-list__tag(
    :class="{ 'is-premium': premium }"
  )
    i.icon.icon-premium(v-if="premium")
    span(v-else) Free
  template(v-if="id")
    template(v-if="!isMobile")
      .inspiration-list__item-hover
        span.text(v-if="videoPreview") Preview Video
        BaseButton(
          :isPrimary="true"
          iconBefore="icon-edit"
          @click="chooseTemplate"
        ) Edit
      BasePreloader(
        v-if="showVideoPreview && !isVideoLoaded"
      )
      video.inspiration-list__item-video(
        v-if="showVideoPreview"
        autoplay=true
        muted=true
        loop=true
        @load="isVideoLoaded = true"
      )
        source(:src="videoPreview")
    .scenes__canvas(
      :style="canvasStyle"
    )
      img.scenes__bg(
        v-if="preview"
        :src="preview"
        @load="bgLoaded = true"
      )
      template(
        v-if="item && item.type"
      )
        CanvasElement.inspiration-element--image(
          v-if="removeBgSuccess"
          :item="item"
          :index="index"
          :isSelectable="false"
          :isScenePreview="true"
          animationTimeline="sceneTimeline"
          :sceneDuration="duration"
          :timelineSettings="item.timeline_settings"
          :sceneId="id"
          @element-loaded="elementLoaded"
        )
        CanvasElement.inspiration-element--mask(
          :item="maskItem"
          :index="index + 1"
          :isSelectable="false"
          :isScenePreview="true"
          animationTimeline="sceneTimeline"
          :sceneDuration="duration"
          :timelineSettings="maskItem.timeline_settings"
          :sceneId="id"
          @element-loaded="elementLoaded"
        )
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { randomId, isMobile } from '@/assets/scripts/utilities';

import CanvasElement from '@/components/ProjectCanvas/CanvasContainer/children/CanvasElement.vue';

export default {
  name: 'InspirationListItem',
  components: {
    CanvasElement,
  },
  data() {
    return {
      bgLoaded: true,
      heroLoaded: false,
      item: {},
      maskItem: {},
      isHovered: false,
      isMobile: isMobile(),
      isVideoLoaded: false,
      canvasWidth: 300,
    };
  },
  props: {
    index: { type: Number, default: 0 },
    // if id 0 means it doesnt have the right filter, eg. `is_hero_wo_bg` true is missing
    id: { required: true, type: Number, default: 0 },
    templateId: { type: Number, default: 0 },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    duration: { type: Number, default: 0 },
    previews: { type: Object, default: () => ({}) },
    background: { type: [String, Object], default: '#fff' },
    heroFile: { type: Object, default: () => ({}) },
    heroElement: { type: Object, default: () => ({}) },
    heroSize: { type: Object, default: () => ({}) },
    isPreview: { type: Boolean, default: false },
    videoPreview: { type: String, default: '' },
    maskUrl: { type: String, default: '' },
    hasBackground: { type: Boolean, default: false },
    premium: { type: Boolean, default: false },
  },
  computed: {
    ...mapState([
      'isLoggedIn',
    ]),
    ...mapState('inspirationList', [
      'heroUserFiles',
      'confirmRemoveBg',
      'removeBgSuccess',
    ]),
    ...mapGetters('canvasElements', [
      'getScenesElementsById',
    ]),
    ...mapState('userData', [
      'requiresSubscription',
    ]),
    ...mapGetters('userData', [
      'isPayingUser',
    ]),
    preview() {
      return this.previews.with_mask;
    },
    isWithoutBackground() {
      return this.confirmRemoveBg;
    },
    containerStyle() {
      const styleObject = {};
      const ratio = this.width / this.height;

      styleObject.width = `${this.canvasWidth}px`;
      styleObject.height = `${this.canvasWidth / ratio}px`;

      return styleObject;
    },
    canvasStyle() {
      const styleObject = {};
      const scale = this.canvasWidth / this.width;

      styleObject.width = `${this.width}px`;
      styleObject.height = `${this.height}px`;

      if (this.background) {
        if (this.background.points) {
          // Gradient
          styleObject.background = `linear-gradient(${this.background.degree}deg`;
          const { points } = this.background;

          for (let i = 0; i < points.length; i += 1) {
            const point = points[i];
            styleObject.background += `, ${point.color} ${point.percentage}%`;
          }
          styleObject.background += ')';
        } else if (this.background.color1) {
          // Old Gradient
          styleObject.background = `linear-gradient(${this.background.degree}deg, ${this.background.color1}, ${this.background.color2})`;
        } else if (this.background.color) {
          // Old color format { color: '#fff' }
          styleObject.background = this.background.color;
        } else {
          styleObject.background = this.background;
        }
      }

      if (scale > 0 && scale !== 1) {
        styleObject.transform = `scale(${scale})`;
      }

      return styleObject;
    },
    isLoaded() {
      if (this.id === 0) return true;
      return this.bgLoaded && this.heroLoaded;
    },
    showVideoPreview() {
      if (this.isLoaded && this.videoPreview && this.isHovered && !this.isMobile) return true;
      return false;
    },
    hero() {
      /**
       *  original_name: "mqJ9i1xPL8.png", file_type: "image/jpeg", file_ext: "png", has_removed_bg: true,…}
       *  download_link: "https://staging-v4-api.offeo.com/storage/temporary-files/1/1-loTwQUStUX.png"
       *  download_link_removedbg: "https://staging-v4-api.offeo.com/storage/temporary-files/1/1-DiJFKUvlR1_removed_bg.png"
       *  file_ext: "png"
       *  file_type: "image/jpeg"
       *  has_removed_bg: true
       *  original_name: "mqJ9i1xPL8.png"
       *  thumb_link: "https://staging-v4-api.offeo.com/storage/temporary-files/1/1-loTwQUStUX-thumb.png"
       *  thumb_link_removedbg: "https://staging-v4-api.offeo.com/storage/temporary-files/1/1-DiJFKUvlR1_removed_bg-thumb.png"
       *  w_960: "https://staging-v4-api.offeo.com/storage/temporary-files/1/1-loTwQUStUX-w_960.png"
       *  w_960_removedbg: "https://staging-v4-api.offeo.com/storage/temporary-files/1/1-DiJFKUvlR1_removed_bg-w_960.png"
       *  ext: "png"
       */
      return this.heroFile.data[0];
    },
  },
  methods: {
    ...mapMutations([
      'setShowAuth',
      'setShowUpgradeSubscription',
      'setShowMobileSmartCreatePreview',
    ]),
    ...mapMutations('inspirationList', [
      'setSelectedInspirationListItem',
    ]),
    chooseTemplate() {
      console.log('chooseTemplateMobile', this.templateId);
      // console.log('InspirationListitem chooseTemplate', this.templateId);
      // if (!this.isLoggedIn || this.isMobile) {
      //   this.setSelectedInspirationListItem({
      //     index: this.index,
      //     id: this.id,
      //     templateId: this.templateId,
      //     width: this.width,
      //     height: this.height,
      //     duration: this.duration,
      //     background: this.background,
      //     previews: this.previews,
      //     hero: this.hero,
      //     videoPreview: this.videoPreview,
      //   });
      // }

      this.$emit('choose-template', {
        templateId: this.templateId,
        useMask: this.hasBackground,
        maskItem: this.maskItem,
      });
    },
    getItem(isMask) {
      const element = cloneDeep(this.heroElement);
      // console.log('getItem element', element);

      // console.log('element', this.imageSize.width, this.imageSize.height);
      if (!element
        || (this.heroSize.width === 0 && this.heroSize.height === 0)) return {};

      element.data.id = randomId();

      if (isMask) {
        element.size.height = element.size.width;
        element.type = 'masks';
        element.isImage = false;
        element.data.url = this.maskUrl || element.data.url;
        element.data.image.url = this.hero.w_960 || '';
        element.data.image.removedbgUrl = this.hero.w_960_removedbg || '';
        element.data.image.isNew = true;
      } else {
        element.type = 'images';
        element.isImage = true;
        const ratio = this.heroSize.width / this.heroSize.height;
        let tempWidth = element.size.width;
        let tempHeight = tempWidth / ratio;

        if (tempHeight > element.size.height) {
          tempHeight = element.size.height;
          tempWidth = tempHeight * ratio;
        }

        element.size.width = tempWidth;
        element.size.height = tempHeight;
        element.data.url = this.hero.w_960 || '';
        element.data.urlHd = this.hero.download_link || '';
        element.data.thumb = this.hero.thumb_link || '';
        element.data.removedBgUrl = this.hero.w_960_removedbg || '';
        element.data.removedBgUrlHd = this.hero.download_link_removedbg || '';
        element.data.removedBgThumb = this.hero.thumb_link_removedbg || '';
        element.data.useRemovedBg = !this.hasBackground;
        element.data.image.top = 0;
        element.data.image.left = 0;
        element.data.image.width = '100%';
        element.data.image.height = '100%';
      }

      return element;
    },
    elementLoaded() {
      setTimeout(() => {
        this.heroLoaded = true;
      }, 750);
    },
    setItemAndMaskItem() {
      console.log('setItemAndMaskItem');
      this.item = this.getItem();
      this.maskItem = this.getItem(true);
    },
    getCanvasWidth() {
      let width = 300;

      if (this.isMobile) {
        const windowWidth = window ? window.innerWidth : 320;
        const contentPadding = 40;
        const canvasMargin = 5;
        width = (windowWidth - contentPadding) / 2 - canvasMargin;
      }

      if (this.isPreview) {
        width = 165;
      }

      if (this.height > this.width) {
        width = 240;
      }

      this.canvasWidth = width;
    },
  },
  beforeMount() {
    this.getCanvasWidth();
    this.bgLoaded = false;
  },
  mounted() {
    this.getCanvasWidth();
    this.$emit('inspiration-list-item-loaded');
  },
  watch: {
    'heroSize.width': {
      handler(val) {
        if (val) {
          this.setItemAndMaskItem();
        }
      },
      immediate: true,
    },
    isLoaded(val) {
      if (val) {
        this.$emit('loaded');
      }
    },
  },
};
</script>

<style lang="scss">
.inspiration-list__item.is-hidden {
  display: none;
}

.inspiration-list__item:not(.is-hidden) {
  width: 300px;
  height: auto;
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 40px 20px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(120deg, white 40%, #ddd, white 60%);
  border-radius: 4px;
  box-shadow: 0 6px 15px rgba(50, 50, 50, 0.1);
  background-size: 400% 400%;

  .smart-create.is-mobile & {
    margin: 0 2px 4px 2px;
    background: linear-gradient(120deg, $darkGrey800 30%, $darkGrey900, $darkGrey800 70%);
  }

  &:not(.show-mask) {
    .inspiration-element--mask {
      display: none;
    }
  }

  &.show-mask {
    .inspiration-element--image {
      display: none;
    }
  }

  &.is-preview {
    width: 165px;
  }

  &.is-loading {
    animation: gradientBG 10s linear infinite;
    cursor: auto;

    .scenes__canvas {
      opacity: 0 !important;
    }
  }

  &:not(.is-loading) {
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);

      .inspiration-list__item-hover {
        opacity: 1;
      }
    }
  }

  .scenes__canvas {
    transform-origin: top left;
    transition: opacity 0.35s ease-in-out;
    opacity: 1 !important;

    .scenes__fg-black,
    .scenes__fg-white,
    .scenes__bg,
    .scenes__overlay,
    .scenes__hero {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .scenes__fg-white {
      mix-blend-mode: multiply;
    }

    .scenes__overlay,
    .scenes__fg-black {
      mix-blend-mode: screen;
    }
  }

  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.inspiration-list__item-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  color: #fff;
  padding: 5px 15px;
  z-index: 4;

  .text {
    font-size: 0.875em;
  }

  .btn {
    background: $blue;
    color: #fff;

    &:hover {
      color: $blue;
    }
  }

  .icon {
    font-size: 1.5em;
    display: inline-block;
    margin-right: 5px;
    margin-left: -8px;
  }
}

.inspiration-list__item-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.inspiration-list__tag {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  background: $light;
  border-radius: 4px;
  color: $darkGrey700;
  font-weight: 600;
  font-size: 0.75rem;
  height: 24px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &.is-premium {
    padding: 2px;
    color: $amber;
    font-size: 1rem;
    width: 24px;
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
